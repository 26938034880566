<template>
    <div style="display: flex;flex-direction: column;height: 100%;max-width: 940px;margin: auto;">
        <div style="flex: 1;" class="cards">
            <div class="card">
                <div class="title" style="margin-bottom: 32px;">设置欢迎话术</div>
                <textarea class="saying" id="saying"
                    style="resize:none;border: none;outline: none;">你好，我是XXX公司的招聘主管，很高兴认识你，我这里有很多技工职位，可以根据你的需求帮你推荐适合你的职位，你想问点什么呢？</textarea>
            </div>
            <div class="card">
                <div style="margin-bottom: 22px;">
                    <span class="title" style="padding-right: 10px;">问答管理</span>
                    <span style="color: #9E9E9E;font-size: 14px;">用于上传招聘信息及基础问答模版</span>
                </div>
                <div style="display: flex;">
                    <div class="method">使用基础题库</div>
                    <div class="method" style="margin-left: 46px;position: relative;">上传文档
                        <input type="file" style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;opacity: 0;"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="l-btn pointer" @click="hNext">开启实时招聘demo</div>
    </div>

</template>
<script setup>
const emits = defineEmits(['next']);
const hNext=()=>{
    emits('next',document.getElementById('saying').value)
}
</script>
<style scoped>
.pointer {
    cursor: pointer;
}

.l-btn {
    height: 70px;
    font-weight: 400;
    font-size: 14px;
    width: 940px;
    max-width: 940px;
    color: #FFFFFF;
    background-color: #323864;
    line-height: 70px;
    text-align: center;
    font-style: normal;
    border-radius: 35px;
    margin: 30px auto auto;
}

.cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
    align-items: center;
}

.saying {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #252525;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.title {
    font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
    font-weight: bold;
    font-size: 16px;
    color: #323864;
    line-height: 22px;
    text-align: left;
}

.card {
    padding: 30px;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px 4px rgba(161, 161, 161, 0.28);
    border-radius: 20px;
    width: 660px;
    max-width: 660px;
}

.method {
    width: 204px;
    height: 71px;
    text-align: center;
    line-height: 71px;
    border-radius: 10px;
    color: #323864;
    border: 1px solid #323864;
    font-weight: 600;
    font-size: 14px;
}
</style>